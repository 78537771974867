//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    teamValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ruleForm: {
        teamId: null,
        Name: null,
        Phone: null,
        addres: null,
      },
      rules: {
        addres: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    opened() {
      Object.assign(this.$data, this.$options.data());
    },
    onConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$refs.modal.loadBtn(true);
          this.ruleForm.teamId = this.teamValue;
          this.$http
            .post("/User/AddUser.ashx", this.ruleForm)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "成员添加成功",
                  type: "success",
                });
                this.$modal.hide("createUser");
                this.$emit("success");
              }
            })
            .finally(() => this.$refs.modal.loadBtn(false));
        } else {
          return;
        }
      });
    },
  },
};
